var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({staticClass:"project-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.items},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.hideTitle || _vm.create)?_c('v-toolbar',{attrs:{"flat":""}},[(!_vm.hideTitle)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$tc('project.title', 2))+" ")]):_vm._e(),_c('v-spacer'),(_vm.create)?_c('v-btn',{attrs:{"outlined":"","elevation":"0","color":"secondary"},on:{"click":_vm.clickAdd}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('form.action.add'))+" ")])],1):_vm._e()],1):_vm._e(),_vm._t("edit",function(){return [_c('project-dialog',_vm._g({attrs:{"item":_vm.itemToEdit},model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}},_vm.$listeners))]},{"item":_vm.itemToEdit}),_vm._t("delete",function(){return [_c('confirm-dialog',{on:{"confirm":_vm.onDeleteConfirm},model:{value:(_vm.showDelete),callback:function ($$v) {_vm.showDelete=$$v},expression:"showDelete"}})]},{"item":_vm.itemToDelete})]},proxy:true},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateString(item.from))+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateString(item.to))+" ")]}},{key:"item.projectStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("form.field.projectStatus." + (String(item.projectStatus).toLowerCase()))))+" ")]}},{key:"item.budget",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.budget))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.editable(item.id))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickEdit(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]):_vm._e(),(_vm.deletable(item.id))?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickDelete(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e()]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
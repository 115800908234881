
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import { required, ValidationForm, projectPlansFrom, projectPlansTo } from '@/utils/validation'
import { useGetUsers } from '@/api/users'
import { Project, ProjectInput } from '@/api/interfaces'
import DateInput from '@/components/common/date-input.vue'
import CurrencyInput from '@/components/common/currency-input.vue'
import { useClientRepository } from '@/api/repository/client-repository'
import { useProjectRepository } from '@/api/repository/project-repository'
import { usePlanRepository } from '@/api/repository/plan-repository'
import { isOfTypeWithValue } from '@/utils/isOfTypeWithValue'

export default defineComponent({
  name: 'project-dialog',
  components: {
    DateInput,
    CurrencyInput,
  },
  props: {
    item: {
      type: Object as PropType<ProjectInput>,
      required: true,
    },
  },
  setup: (props, { root, attrs, emit }) => {
    const form = ref<ValidationForm | null>(null)
    const formIsValid = ref(false)
    const project = ref<ProjectInput | Project>({ ...props.item })

    const clientRepository = useClientRepository()
    const { getAll: getAllClients, data: clients } = clientRepository.useGetAll()

    const projectRepository = useProjectRepository()
    const { getAll: getAllProjects, data: projects } = projectRepository.useGetAll()

    const planRepository = usePlanRepository()
    const { getAll: getAllPlans, data: plans } = planRepository.useGetAll()

    const projectPlans = computed(() => {
      return plans.value.filter((plan) => {
        if (!isOfTypeWithValue<Project, ProjectInput>(project.value, 'id')) return false
        return plan.projectId === project.value.id
      })
    })

    const { getUsers, data: users } = useGetUsers()

    const { getProjectStatus, data: projectStatus } = projectRepository.useGetProjectStatus()

    const fromIsActive = ref(false)
    const toIsActive = ref(false)

    const isParentProject = computed(() => {
      let childProjects = projects?.value.filter((p) => {
        if ('id' in project.value) {
          if (p.parentId === project.value.id) return true
          return false
        }
        return false
      }).length

      if (childProjects !== 0) return true

      return false
    })

    const allowedProjects = computed(() =>
      projects.value.filter((p) => {
        if (plans.value.some((plan) => plan.projectId === p.id)) return false
        // edit existing project
        if ('id' in project.value) {
          if (p.id === project.value.id) return false
          if (p.parentId === project.value.id) return false
        }
        return true
      })
    )

    function formatDateString(dateString: string) {
      const [year, month, day] = dateString.split('-')
      return `${day}.${month}.${year}`
    }

    function clickCancel() {
      emit('input', false)
    }

    function onSubmit() {
      emit('input', false)
      emit('save', { ...project.value })
    }

    watch(
      () => props.item,
      (value) => {
        project.value = value
      }
    )

    watch(
      () => attrs.value,
      (value) => {
        if (!value) return
        project.value = { ...props.item }
        getAllClients({ params: { size: 2000 } })
        getAllProjects({ params: { size: 2000, projectStatus: 'ACTIVE' } })
        getAllPlans({ params: { size: 2000 } })
        getUsers({ size: 2000, page: 0 }, { active: true })
        getProjectStatus({ params: { size: 2000 } })

        form.value?.resetValidation()
      }
    )
    const projectStatusEnum = computed(() =>
      projectStatus.value
        ? projectStatus?.value.map((projectStatus) => {
            return { name: root.$t(`form.field.projectStatus.${projectStatus.toLowerCase()}`), value: projectStatus }
          })
        : []
    )

    return {
      form,
      plans,
      formIsValid,
      fromIsActive,
      toIsActive,
      isParentProject,
      projectPlans,
      icons: { mdiCalendar },
      project,
      projectStatusEnum,
      clients,
      allowedProjects,
      users,
      validation: { required, projectPlansFrom, projectPlansTo },
      formatDateString,
      clickCancel,
      onSubmit,
    }
  },
})


import { defineComponent, PropType, ref } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiPlus, mdiPencil, mdiDelete } from '@mdi/js'
import { Project } from '@/api/interfaces'
import { useFormat } from '@/utils/format'
import { useProjectRepository } from '@/api/repository/project-repository'
import ProjectDialog from '@/components/project/project-dialog.cmp.vue'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'

export default defineComponent({
  name: 'project-table',
  components: {
    ConfirmDialog,
    ProjectDialog,
  },
  props: {
    items: {
      type: Array as PropType<Project[]>,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Function as PropType<(project: Project) => boolean>,
      default: () => false,
    },
    deletable: {
      type: Function as PropType<(project: Project) => boolean>,
      default: () => false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const { formatCurrency, formatDateString } = useFormat()
    const { dummyProjectInput } = useProjectRepository()
    const showEdit = ref(false)
    const itemToEdit = ref(dummyProjectInput())
    const showDelete = ref(false)
    const itemToDelete = ref<Project | undefined>()

    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$t('form.field.id').toString(),
        value: 'id',
        width: '80px',
      },
      {
        text: root.$t('form.field.name').toString(),
        value: 'name',
        width: '230px',
      },
      {
        text: root.$t('form.field.parent').toString(),
        value: 'parentName',
        sortable: false,
        width: '230px',
      },
      {
        text: root.$t('form.field.projectManager').toString(),
        value: 'projectManagerFullName',
        sortable: false,
        width: '230px',
      },
      {
        text: root.$t('form.field.from').toString(),
        value: 'from',
        width: '180px',
      },
      {
        text: root.$t('form.field.to').toString(),
        value: 'to',
        width: '180px',
      },
      {
        text: root.$t('form.field.projectStatus.title').toString(),
        value: 'projectStatus',
        width: '180px',
      },
      {
        text: root.$t('form.field.budget').toString(),
        value: 'budget',
        width: '250px',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '80px',
        align: 'end',
      },
    ]

    async function clickAdd() {
      emit('click:add')
      itemToEdit.value = { ...dummyProjectInput() }
      showEdit.value = true
    }

    async function clickEdit(item: Project) {
      emit('click:edit', { ...item })
      itemToEdit.value = { ...item }
      showEdit.value = true
    }

    async function clickDelete(item: Project) {
      itemToDelete.value = { ...item }
      showDelete.value = true
      emit('click:delete', { ...item })
    }

    async function onDeleteConfirm() {
      if (!itemToDelete.value) return
      showDelete.value = false
      emit('delete', { ...itemToDelete.value })
    }

    return {
      icons: { mdiPlus, mdiPencil, mdiDelete },
      formatDateString,
      formatCurrency,
      tableHeaders,
      clickAdd,
      clickEdit,
      clickDelete,
      onDeleteConfirm,
      showEdit,
      itemToEdit,
      showDelete,
      itemToDelete,
    }
  },
})

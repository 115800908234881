import { render, staticRenderFns } from "./project-overview.vue?vue&type=template&id=82200c5c&scoped=true&"
import script from "./project-overview.vue?vue&type=script&lang=ts&"
export * from "./project-overview.vue?vue&type=script&lang=ts&"
import style0 from "./project-overview.vue?vue&type=style&index=0&id=82200c5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82200c5c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardText,VCardTitle,VCol,VContainer,VIcon,VRow,VSpacer})

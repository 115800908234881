
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete } from '@mdi/js'
import { Rights } from '@/api/rights'
import { useAuthGetters, useNotify } from '@/store'
import { usePagination } from '@/utils/pagination'
import { Project, ProjectInput } from '@/api/interfaces'
import { useProjectRepository } from '@/api/repository/project-repository'
import { useClientRepository } from '@/api/repository/client-repository'
import projectTable from '@/components/project/project-table.cmp.vue'
import projectDialog from '@/components/project/project-dialog.cmp.vue'
import CommonAutocomplete from '@/components/common/common-autocomplete.vue'
import { debounce } from 'lodash'

export default defineComponent({
  name: 'project-overview',
  components: {
    projectTable,
    projectDialog,
    CommonAutocomplete,
  },
  setup(props, { root }) {
    const { vuetifyTableOptions, paginationParams } = usePagination()
    vuetifyTableOptions.value.sortBy = ['id']
    vuetifyTableOptions.value.sortDesc = [true]

    const { addNotification } = useNotify()
    const projectRepository = useProjectRepository()
    const { getAll: getAllProjects, data: projects, isLoading, total } = projectRepository.useGetAll()
    const { save } = projectRepository.useSave()
    const { remove } = projectRepository.useRemove()

    const { getProjectStatus: getAllProjectStatus, data: allProjectStatus } = projectRepository.useGetProjectStatus()
    getAllProjectStatus()

    const clientRepository = useClientRepository()
    const {
      getAll: getAllClients,
      data: clients,
      paginationResponse: clientPaginationResponse,
    } = clientRepository.useGetAll()
    const filters: {
      name?: string
      clientId?: number
      projectStatus?: string | undefined
      dateFrom?: string
      dateTo?: string
    } = reactive({
      name: undefined,
      clientId: undefined,
      projectStatus: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    })
    const activeYear = ref(new Date().getFullYear())
    setYear(activeYear.value)
    const years = computed(() => {
      const years: { text: number; value: number }[] = []
      const currentYear = new Date().getFullYear()
      for (let i = 2017; i <= currentYear; i++) {
        years.push({ text: i, value: i })
      }
      return years
    })

    const projectsSearchInput = ref('')
    const {
      getAll: getAllForSearch,
      data: projectsFound,
      isLoading: searchIsLoading,
      paginationResponse: projectPaginationResponse,
    } = projectRepository.useGetAll()

    const { hasRight } = useAuthGetters()
    const canCreateProject = computed(() => hasRight.value(Rights.PROJECT_CREATE))

    const editIsActive = ref(false)
    const deleteIsActive = ref(false)
    const projectToEdit = ref<ProjectInput>({ ...projectRepository.dummyProjectInput() })
    const projectToDelete = ref<Project | null>(null)

    async function updateProjects() {
      await getAllProjects({ params: { ...paginationParams.value, ...filters } })
    }

    async function onSaveProject(project: Project) {
      await save(project)
      addNotification({ text: root.$t('form.save.success').toString(), type: 'success' })
      updateProjects()
    }

    async function onDeleteProject(project: Project) {
      await remove(project.id)
      addNotification({ text: root.$t('form.delete.success').toString(), type: 'success' })
      updateProjects()
    }

    function setYear(year?: number) {
      if (year === null) {
        filters.dateFrom = undefined
        filters.dateTo = undefined
      } else {
        filters.dateFrom = `${year}-01-01`
        filters.dateTo = `${year}-12-31`
      }
    }

    function gotoDetails(project: Project) {
      root.$router.push({ name: 'projectProjectsDetails', params: { id: String(project.id) } })
    }

    function canUpdateProject() {
      return hasRight.value(Rights.PROJECT_UPDATE)
    }

    function canDeleteProject() {
      return hasRight.value(Rights.PROJECT_DELETE)
    }

    watch(filters, () => {
      updateProjects()
    })

    watch(activeYear, (activeYear) => {
      setYear(activeYear)
    })

    const debounceProjectSearch = debounce((name) => getAllForSearch({ params: { name, size: 15 } }), 500)
    watch(projectsSearchInput, (name) => debounceProjectSearch(name))

    const clientSearchInput = ref('')
    const debounceClientSearch = debounce((name) => getAllClients({ params: { name, size: 15 } }), 500)
    watch(clientSearchInput, (name) => debounceClientSearch(name))

    const projectStatusSearchInput = ref('')
    const debounceProjectStatusSearch = debounce((val) => getAllProjectStatus({ params: { val, size: 15 } }), 500)
    watch(projectStatusSearchInput, (name) => debounceProjectStatusSearch(name))

    const filterPageCounter = ref<number>(1)

    function loadProjects() {
      if (filterPageCounter.value <= projectPaginationResponse.value.totalPages) {
        getAllForSearch({ params: { page: filterPageCounter.value++, size: 15 } })
      }
    }

    function loadClients() {
      if (filterPageCounter.value <= clientPaginationResponse.value.totalPages) {
        getAllClients({ params: { page: filterPageCounter.value++, size: 15 } })
      }
    }

    function resetFilterPageCounter() {
      filterPageCounter.value = 1
    }

    const projectStatus = ref({ name: root.$t(`form.field.projectStatus.aktiv`), value: 'ACTIVE' })
    setProjectStatusFilter(projectStatus.value.value)

    function setProjectStatusFilter(status: string): void {
      filters.projectStatus = status
    }

    watch(projectStatus, (projectStatus) => {
      if (projectStatus === null) {
        filters.projectStatus = undefined
      } else {
        setProjectStatusFilter(String(projectStatus))
      }
    })

    const projectStatusEnum = computed(() =>
      allProjectStatus.value
        ? allProjectStatus?.value.map((status) => {
            return { name: root.$t(`form.field.projectStatus.${status.toLowerCase()}`), value: status }
          })
        : []
    )

    return {
      filters,
      activeYear,
      projectsSearchInput,
      projectsFound,
      searchIsLoading,
      icons: { mdiPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete },
      canCreateProject,
      projects,
      updateProjects,
      onSaveProject,
      onDeleteProject,
      canUpdateProject,
      canDeleteProject,
      deleteIsActive,
      projectToDelete,
      editIsActive,
      projectToEdit,
      isLoading,
      total,
      clients,
      years,
      vuetifyTableOptions,
      gotoDetails,
      clientSearchInput,
      loadProjects,
      loadClients,
      projectStatusEnum,
      projectStatus,
      resetFilterPageCounter,
    }
  },
})

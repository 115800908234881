
import { mdiCheck, mdiPencil, mdiPlus, mdiAlertCircle } from '@mdi/js'
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import PlanTable from '@/components/plan/plan-table.cmp.vue'
import ProjectTable from '@/components/project/project-table.cmp.vue'
import OrderTable from '@/components/order/order-table.cmp.vue'
import InvoiceTable from '@/components/invoice/invoice-table.cmp.vue'
import ProjectEdit from '@/components/project/project-dialog.cmp.vue'
import { Invoice, InvoiceInput, Order, OrderInput, Plan, Project } from '@/api/interfaces'
import { useAuthGetters, useNotify } from '@/store'
import { Rights } from '@/api/rights'
import { useGetUsers } from '@/api/users'
import { useFormat } from '@/utils/format'
import { usePagination } from '@/utils/pagination'
import { useProjectRepository } from '@/api/repository/project-repository'
import { usePlanRepository } from '@/api/repository/plan-repository'
import { useOrderRepository } from '@/api/repository/order-repository'
import { useInvoiceRepository } from '@/api/repository/invoice-repository'

export default defineComponent({
  name: 'project-details',
  components: {
    ProjectTable,
    PlanTable,
    OrderTable,
    InvoiceTable,
    ProjectEdit,
  },
  props: {
    id: {
      type: Number || null,
      default: null,
    },
  },
  setup: (props, { root }) => {
    const loaded = ref(false)
    const editIsActive = ref(false)

    const { formatCurrency, formatDateString } = useFormat()
    const { addNotification } = useNotify()
    const { hasRight, projectsAsManager, currentUser } = useAuthGetters()

    const { getUsers: getUsersRequest, data: users } = useGetUsers()

    const projectRepository = useProjectRepository()
    const {
      getAll: getAllChildrenProjects,
      data: children,
      isLoading: childrenAreLoading,
      total: totalChildren,
    } = projectRepository.useGetAll()
    const { getAll: getAllProjects, data: projects } = projectRepository.useGetAll()
    const project = ref<Project>()

    const { getDetails, data: projectDetails } = projectRepository.useGetDetails()
    const { save: saveProject } = projectRepository.useSave()

    const { vuetifyTableOptions: childrenTableOptions, paginationParams: childrenPaginationParams } = usePagination()
    childrenTableOptions.value.sortBy = ['id']
    childrenTableOptions.value.sortDesc = [true]

    function updateChildren() {
      getAllChildrenProjects({ params: { ...childrenPaginationParams.value, parentId: props.id } })
    }

    function gotoChild(child: Project) {
      root.$router.push({ name: 'projectProjectsDetails', params: { id: String(child.id) } })
    }

    const withOrders = computed(
      () => hasRight.value(Rights.ORDER_READ_ALL) || project.value?.projectManagerId === currentUser.value.id
    )

    const canUpdateProject = computed(
      () => hasRight.value(Rights.PROJECT_UPDATE_ALL) || projectsAsManager.value.includes(props.id)
    )

    async function onSaveProject(item: Project) {
      if (!project.value) return
      await saveProject(item)
      project.value = item
    }

    const breadcrumbs = computed(() => {
      const breadcrumbList = [
        {
          text: root.$t('project.title').toString(),
          to: { name: 'projectProjects', params: {} },
          exact: true,
          disabled: false,
        },
      ]

      let currentProject = project.value

      while (currentProject) {
        const parentProject = projects.value.find((p) => p.id === currentProject?.parentId)

        if (parentProject) {
          breadcrumbList.splice(1, 0, {
            text: parentProject.name,
            to: { name: 'projectProjectsDetails', params: { id: String(parentProject.id) } },
            exact: true,
            disabled: false,
          })
          currentProject = parentProject
        } else {
          break
        }
      }

      if (project.value?.name) {
        breadcrumbList.push({
          text: project.value.name,
          to: { name: 'projectProjectsDetails', params: { id: String(project.value.id) } },
          exact: true,
          disabled: true,
        })
      }

      return breadcrumbList
    })

    const margin = computed(() =>
      projectDetails.value?.revenue
        ? (projectDetails.value.revenue - projectDetails.value.costs) / projectDetails.value.revenue
        : 0
    )

    const ebit = computed(() =>
      projectDetails.value ? projectDetails.value.revenue - projectDetails.value.costs : 0
    )

    const volumeProgress = computed(() => {
      if (!(project.value && projectDetails.value)) return 0
      return project.value.budget === 0 ? 100 : (projectDetails.value.ordered / project.value.budget) * 100
    })

    const timeProgress = computed(() => {
      if (!project.value) return 0
      const today = new Date().getTime()
      const from = Date.parse(project.value.from)
      const to = Date.parse(project.value.to)
      const day = 1000 * 3600 * 24
      const totalDays = (to - from) / day
      const pastDays = (today - from) / day
      const time = totalDays === 0 ? 100 : Math.min(100, Math.ceil((pastDays / totalDays) * 100))
      return time
    })

    const revenueProgress = computed(() => {
      if (!(project.value && projectDetails.value)) return 0
      return project.value.budget === 0 ? 100 : (projectDetails.value.revenue / project.value.budget) * 100
    })

    const billingProgress = computed(() => {
      if (!(project.value && projectDetails.value)) return 0
      return project.value.budget === 0 ? 100 : (projectDetails.value.invoiced / project.value.budget) * 100
    })

    const inPaymentProgress = computed(() => {
      if (!(project.value && projectDetails.value)) return 0
      return project.value.budget === 0 ? 100 : (projectDetails.value.paidNet / project.value.budget) * 100
    })

    const planRepository = usePlanRepository()
    const {
      getAll: getAllPlans,
      data: plans,
      isLoading: plansAreLoading,
      total: totalPlans,
    } = planRepository.useGetAll()
    const { save: savePlan } = planRepository.useSave()
    const { remove: removePlan } = planRepository.useRemove()
    const { vuetifyTableOptions: planTableOptions, paginationParams: planPaginationParams } = usePagination()
    planTableOptions.value.sortBy = ['id']
    planTableOptions.value.sortDesc = [true]

    function updatePlans() {
      getAllPlans({ params: { ...planPaginationParams.value, projectId: props.id } })
    }

    async function onSavePlans(plan: Plan) {
      await savePlan(plan)
      addNotification({ text: root.$t('form.save.success').toString(), type: 'success' })
      updatePlans()
      getAllProjects({ params: { size: 2000 } })
      getDetails(props.id)
    }

    async function onDeletePlan(plan: Plan) {
      await removePlan(plan.id)
      updatePlans()
      getAllProjects({ params: { size: 2000 } })
      getDetails(props.id)
    }

    const canCreatePlan = computed(
      () => hasRight.value(Rights.PLAN_CREATE_ALL) || projectsAsManager.value.includes(props.id)
    )

    function canDeletePlan(plan: Plan) {
      return (
        plan.bookedHours === 0 &&
        (hasRight.value(Rights.PLAN_DELETE_ALL) || projectsAsManager.value.some((id) => id === plan.projectId))
      )
    }

    function canEditPlan(plan: Plan) {
      return hasRight.value(Rights.PLAN_UPDATE_ALL) || projectsAsManager.value.some((id) => id === plan.projectId)
    }

    const orderRepository = useOrderRepository()
    const {
      getAll: getAllOrders,
      data: orders,
      isLoading: ordersAreLoading,
      total: totalOrders,
    } = orderRepository.useGetAll()
    const { save: saveOrder } = orderRepository.useSave()
    const { remove: removeOrder } = orderRepository.useRemove()
    const { vuetifyTableOptions: orderTableOptions, paginationParams: orderPaginationParams } = usePagination()
    orderTableOptions.value.sortBy = ['id']
    orderTableOptions.value.sortDesc = [true]

    function updateOrders() {
      if (!withOrders) return
      getAllOrders({ params: { ...orderPaginationParams.value, projectIds: props.id } })
    }

    async function onSaveOrder(order: Order | OrderInput) {
      await saveOrder(order)
      updateOrders()
      getAllProjects({ params: { size: 2000 } })
      getDetails(props.id)
    }

    async function onDeleteOrder(order: Order) {
      await removeOrder(order.id)
      updateOrders()
      getAllProjects({ params: { size: 2000 } })
      getDetails(props.id)
    }

    const canCreateOrder = computed(
      () => hasRight.value(Rights.ORDER_CREATE_ALL) || projectsAsManager.value.includes(props.id)
    )

    function canDeleteOrder(order: Order) {
      return hasRight.value(Rights.ORDER_DELETE_ALL) || projectsAsManager.value.some((id) => id === order.projectId)
    }

    function canEditOrder(order: Order) {
      return hasRight.value(Rights.ORDER_UPDATE_ALL) || projectsAsManager.value.some((id) => id === order.projectId)
    }

    const invoiceRepository = useInvoiceRepository()

    const {
      getAll: getProjectInvoices,
      data: projectInvoices,
      isLoading: isLoadingProjectInvoices,
      total: totalProjectInvoices,
    } = invoiceRepository.useGetAll()

    const { vuetifyTableOptions: invoiceTableOptions, paginationParams: invoicePaginationParams } = usePagination()
    invoiceTableOptions.value.sortBy = ['id']
    invoiceTableOptions.value.sortDesc = [true]

    const { save: saveInvoice } = invoiceRepository.useSave()
    const { remove: removeInvoice } = invoiceRepository.useRemove()

    async function updateInvoices() {
      await getProjectInvoices({ params: { ...invoicePaginationParams.value, projectId: props.id } })
    }

    async function onSaveInvoice(invoice: Invoice | InvoiceInput) {
      await saveInvoice(invoice)
      updateInvoices()
      updateOrders()
      getAllProjects({ params: { size: 2000 } })
      getDetails(props.id)
    }

    async function onDeleteInvoice(invoice: Invoice) {
      await removeInvoice(invoice.id)
      updateInvoices()
      getAllProjects({ params: { size: 2000 } })
      getDetails(props.id)
    }

    const canCreateInvoice = computed(
      () => hasRight.value(Rights.INVOICE_CREATE_ALL) || projectsAsManager.value.includes(props.id)
    )

    const canReadInvoice = computed(
      () => hasRight.value(Rights.INVOICE_READ_ALL) || projectsAsManager.value.includes(props.id)
    )

    function canDeleteInvoice() {
      return hasRight.value(Rights.INVOICE_DELETE_ALL)
    }

    function canEditInvoice(invoice: Invoice) {
      return (
        hasRight.value(Rights.INVOICE_UPDATE_ALL) || projectsAsManager.value.some((id) => id === invoice.projectId)
      )
    }
    async function init() {
      try {
        await getUsersRequest()
      } catch (error: any) {
        error.userMessage = root.$t('users.get.error')

        throw error
      }
    }
    init()

    watch(
      () => props.id,
      async () => {
        await getAllProjects({ params: { size: 2000 } })
        project.value = projects?.value.find((p) => p.id === props.id)
        if (!project.value) return
        await getDetails(project.value.id)
        loaded.value = true

        updatePlans()
        updateOrders()
        updateChildren()
        updateInvoices()
      },
      { immediate: true }
    )

    function getLatestBillDate(invoiceArray: Invoice[]): string | null {
      if (invoiceArray.length === 0) {
        return null
      }

      return invoiceArray.reduce((a, b) => (a.date > b.date ? a : b)).date
    }

    function getLatestBillDateForProjectHierarchy(): string {
      let latestBillDate

      function iterateProjects(currentProject) {
        // Find invoices for this project
        const latestInvoiceDate = getLatestBillDate(projectInvoices.value)

        // Update latest bill date if necessary
        if (!latestBillDate || (latestInvoiceDate && latestInvoiceDate > latestBillDate)) {
          latestBillDate = latestInvoiceDate
        }

        // Recursively iterate through child projects
        const childProjects = projects.value.filter((project) => project.parentId === currentProject.id)
        for (const childProject of childProjects) {
          iterateProjects(childProject)
        }
      }

      iterateProjects(project.value)

      return latestBillDate ? formatDateString(latestBillDate) : '-'
    }

    return reactive({
      loaded,
      editIsActive,
      formatCurrency,
      formatDateString,
      users,
      project,
      projectDetails,
      onSaveProject,
      permissions: {
        canCreatePlan,
        canEditPlan,
        canDeletePlan,
        canCreateOrder,
        canEditOrder,
        canDeleteOrder,
        canUpdateProject,
        canCreateInvoice,
        canReadInvoice,
        canEditInvoice,
        canDeleteInvoice,
      },
      breadcrumbs,
      margin,
      ebit,
      volumeProgress,
      timeProgress,
      revenueProgress,
      billingProgress,
      inPaymentProgress,
      plans,
      plansAreLoading,
      totalPlans,
      onSavePlans,
      onDeletePlan,
      updatePlans,
      planTableOptions,
      orders,
      ordersAreLoading,
      totalOrders,
      onSaveOrder,
      onDeleteOrder,
      updateOrders,
      orderTableOptions,
      children,
      childrenAreLoading,
      childrenTableOptions,
      totalChildren,
      updateChildren,
      projectInvoices,
      isLoadingProjectInvoices,
      totalProjectInvoices,
      invoiceTableOptions,
      onSaveInvoice,
      onDeleteInvoice,
      updateInvoices,
      icons: { mdiCheck, mdiPencil, mdiPlus, mdiAlertCircle },
      gotoChild,
      getLatestBillDateForProjectHierarchy,
    })
  },
})
